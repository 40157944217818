import React, { useEffect, useState } from "react"
import axios from "axios"
import { Baseurl } from "../../Baseurl"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const AddPartner = () => {
  const [partnerTypes, setPartnerTypes] = useState([])
  const [states, setStates] = useState([])
  const [logo, setLogo] = useState()
  const toggleToast = (type, message) => {
    toast[type](message)
  }
  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllPartnerTypes`)
      .then(response => {
        setPartnerTypes(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching partner types:", error)
      })

    axios
      .get(`${Baseurl}/getAllStates`)
      .then(response => {
        setStates(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching states:", error)
      })
  }, [])

  // const handleLogoChange = event => {
  //   setLogo(event.target.files[0])
  // }
  const [image, setImage] = React.useState("")
  const [imageshow, setImageshow] = useState("")
  const navigate = useNavigate()

  const onchangeImage = e => {
    const blogimage = e.target.files[0]
    console.log("image", blogimage)
    setImageshow(URL.createObjectURL(e.target.files[0]))
    setImage(blogimage)
  }

  const nestedformik = useFormik({
    initialValues: {
      companyName: "",
      partnerType: "",
      website: "",
      city: "",
      state: "",
      address: "",
      googleMapLink: "",
      phone: "",
      keyContactNumber: "",
      email: "",
      mobile: "",
    },

    validationSchema: Yup.object({
      companyName: Yup.string().required("Company Name is required"),
      partnerType: Yup.string().required("Partner Type is required"),
      website: Yup.string().required("Website is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      address: Yup.string().required("Address is required"),
      // phone: Yup.string()
      //   .required("Phone number is required")
      //   .matches(/^[0-9]+$/, "Phone number must contain only numbers")
      //   .min(10, "Phone number must be at least 10 characters")
      //   .max(12, "Phone number cannot exceed 12 characters"),
      mobile: Yup.string()
        .required("mobile number is required")
        .matches(/^[0-9]+$/, "mobile number must contain only numbers")
        .min(10, "mobile number must be at least 10 characters")
        .max(12, "mobile number cannot exceed 12 characters"),
      // keyContactNumber: Yup.string().required("Key Contact Number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),

    // Adjusted onSubmit function in nestedformik

    onSubmit: values => {
      console.log(values)
      const formData = new FormData()
      formData.append("partner_name", values.companyName)
      formData.append("partner_type_id", values.partnerType)
      formData.append("partner_website", values.website)
      formData.append("partner_city", values.city)
      formData.append("partner_state_id", values.state)
      formData.append("partner_address", values.address)
      formData.append("partner_map", values.googleMapLink)
      formData.append("partner_phone", values.phone)
      formData.append("partner_logo", image) // Use logo state variable
      formData.append("partner_contact_name", values.keyContactNumber)
      formData.append("partner_contact_mobile", values.mobile) // Correct field name for mobile
      formData.append("partner_contact_email", values.email)

      axios
        .post(`${Baseurl}/insertPartner`, formData)
        .then(response => {
          console.log("Response:", response.data)
          toggleToast("success", response.data.message)

          nestedformik.resetForm()
          navigate("/view-partner")
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toggleToast("error", error.response.data.message)
            } else {
              toggleToast("error", "Failed to add venue.")
            }
          } else {
            toggleToast("error", "Failed to add venue. Please try again.")
          }
          console.error("Error:", error)
        })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Add Partner" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={nestedformik.handleSubmit}>
                    <Row>
                      {/* Company Name */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="companyName">
                            Company Name<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="companyName"
                            id="companyName"
                            placeholder="Enter Company Name..."
                            className="form-control"
                            value={nestedformik.values.companyName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.companyName &&
                              nestedformik.errors.companyName
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.companyName}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Partner Type */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="partnerType">
                            Partner Type<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="partnerType"
                            id="partnerType"
                            className="form-control"
                            value={nestedformik.values.partnerType}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.partnerType &&
                              nestedformik.errors.partnerType
                            }
                          >
                            <option value="">Select</option>

                            {partnerTypes.length > 0 ? (
                              partnerTypes.map((partnerty, index) => (
                                <option key={index} value={partnerty._id}>
                                  {partnerty.partner_type}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No partner Types available
                              </option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.partnerType}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Website */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="website">
                            Website<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="website"
                            id="website"
                            placeholder="Enter Website..."
                            className="form-control"
                            value={nestedformik.values.website}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.website &&
                              nestedformik.errors.website
                            }
                          />
                          <small>(example : https://www.abc.com)</small>

                          <FormFeedback>
                            {nestedformik.errors.website}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* City */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="city">
                            City<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            placeholder="Enter City..."
                            className="form-control"
                            value={nestedformik.values.city}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.city &&
                              nestedformik.errors.city
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.city}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* State */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="state">
                            State<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="state"
                            id="state"
                            className="form-control"
                            value={nestedformik.values.state}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.state &&
                              nestedformik.errors.state
                            }
                          >
                            <option value="">Select</option>
                            {states.length > 0 ? (
                              states.map((state, index) => (
                                <option key={index} value={state._id}>
                                  {state.state_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>

                          <FormFeedback>
                            {nestedformik.errors.state}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Address */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="address">
                            Address<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Enter Address..."
                            className="form-control"
                            value={nestedformik.values.address}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.address &&
                              nestedformik.errors.address
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.address}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Google Map Link */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="googleMapLink">
                            Google Map Link <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="googleMapLink"
                            id="googleMapLink"
                            placeholder="Enter Google Map Link..."
                            className="form-control"
                            value={nestedformik.values.googleMapLink}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.googleMapLink &&
                              nestedformik.errors.googleMapLink
                            }
                          />
                          
                          <FormFeedback>
                            {nestedformik.errors.googleMapLink}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Phone */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="phone">Phone(Landline)</Label>
                          <Input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Enter Phone Number..."
                            className="form-control"
                            value={nestedformik.values.phone}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.phone &&
                            //   nestedformik.errors.phone
                            // }
                          />
                          {/* <FormFeedback>
                            {nestedformik.errors.phone}
                          </FormFeedback> */}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Key Contact  */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="keyContactNumber">Key Contact</Label>
                          <Input
                            type="text"
                            name="keyContactNumber"
                            id="keyContactNumber"
                            placeholder="Enter Key Contact ..."
                            className="form-control"
                            value={nestedformik.values.keyContactNumber}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.keyContactNumber &&
                            //   nestedformik.errors.keyContactNumber
                            // }
                          />
                          {/* <FormFeedback>
                            {nestedformik.errors.keyContactNumber}
                          </FormFeedback> */}
                        </div>
                      </Col>

                      {/* Email */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="email">
                            Email<em className="starred">*</em>
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter Email Address..."
                            className="form-control"
                            value={nestedformik.values.email}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.email &&
                              nestedformik.errors.email
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.email}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="logo">Logo</Label>

                          <div className="input-group">
                            <Input
                              type="file"
                              className="form-control"
                              id="inputGroupFile02"
                              onChange={onchangeImage}
                            />
                            <Label
                              className="input-group-text"
                              htmlFor="inputGroupFile02"
                            >
                              Upload
                            </Label>
                          </div>
                          {/* <FormFeedback>
                            {nestedformik.errors.logo}
                          </FormFeedback> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="mobile">
                            Mobile <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter mobile Number..."
                            className="form-control"
                            value={nestedformik.values.mobile}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.mobile &&
                              nestedformik.errors.mobile
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.mobile}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddPartner
