import React, { useEffect, useState } from "react"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Baseurl } from "Baseurl"
import { Link } from "react-router-dom"

const ViewExhibition = () => {
  const [exhibitions, setExhibitions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllExhibitions`)
      .then(response => {
        setExhibitions(response.data.data) // Assuming your data is inside the `data` property of the response
        setLoading(false)
      })
      .catch(error => {
        console.error("Error fetching exhibitions:", error)
        setLoading(false)
      })
  }, [])

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "expo_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Type",
        accessorKey: "exhibition_type_id.exhibition_type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Month",
        accessorKey: "expo_month_id.month_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Year",
        accessorKey: "expo_year_id.year_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Start Date",
        accessorKey: "expo_sd",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "End Date",
        accessorKey: "expo_ed",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Website",
        accessorKey: "expo_website",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Organizer",
        accessorKey: "expo_organizer_id.organizer_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "City",
        accessorKey: "expo_city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "State",
        accessorKey: "expo_state_id.state_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Venue",
        accessorKey: "expo_venue_id.venue_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Frequency",
        accessorKey: "expo_frequency",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "expo_status",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="View Exhibition" />
        <div className="Addbuttonform">
          <Link to="/add-exhibition">
            <button className="btn btn-primary">Add Exhibition</button>
          </Link>
        </div>
        {loading ? (
          <p></p>
        ) : exhibitions.length === 0 ? (
          <p>No exhibition records found.</p>
        ) : (
          <TableContainer
            columns={columns}
            data={exhibitions}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            loading={loading}
          />
        )}
      </div>
    </div>
  );
  
}

export default ViewExhibition
