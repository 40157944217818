import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"
// import statesData from "../JsonData/states.json"
// import monthsData from "../JsonData/months.json"
// import yearsData from "../JsonData/years.json"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Baseurl } from "Baseurl"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"

const AddConference = () => {
  const navigate = useNavigate()
  const [image, setImage] = React.useState("")
  const [imageshow, setImageshow] = useState("")
  const toggleToast = (type, message) => {
    toast[type](message)
  }
  const onchangeImage = e => {
    const blogimage = e.target.files[0]
    console.log("image", blogimage)
    setImageshow(URL.createObjectURL(e.target.files[0]))
    setImage(blogimage)
  }
  const nestedformik = useFormik({
    initialValues: {
      conferenceFullName: "",
      conferenceShortName: "",
      month: "",
      year: "",
      startDate: "",
      endDate: "",
      website: "",
      nationalAssociation: "",
      city: "",
      state: "",
      venue: "",
      conferenceType: "",
      organizingSecretaryName: "",
      number: "",
      email: "",
      HostingChapter: "",
      poc: "",
    },

    validationSchema: Yup.object({
      conferenceFullName: Yup.string().required(
        "Conference Full Name is required"
      ),
      conferenceShortName: Yup.string().required(
        "Conference Short Name is required"
      ),
      month: Yup.string().required("Month is required"),
      year: Yup.string().required("Year is required"),
      startDate: Yup.string().required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
      website: Yup.string().required("Website is required"),
      nationalAssociation: Yup.string().required(
        "National Association is required"
      ),
      city: Yup.string().required("City is required"),
      poc: Yup.string().required("Poc is required"),
      state: Yup.string().required("State is required"),
      venue: Yup.string().required("Venue is required"),
      conferenceType: Yup.string().required("Conference Type is required"),
      organizingSecretaryName: Yup.string(),
      number: Yup.string()
        .required("Please Enter Your Phone")
        .matches(/^[0-9]+$/, "Phone number must contain only numbers")
        .min(10, "Phone number must be at least 10 characters")
        .max(12, "Phone number cannot exceed 12 characters"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),

    onSubmit: values => {
      console.log(values) // Logging the form values submitted
      const formData = new FormData()
      formData.append("con_fullname", values.conferenceFullName)
      formData.append("con_shortname", values.conferenceShortName)
      formData.append("con_month_id", values.month)
      formData.append("con_year_id", values.year)
      formData.append("con_sd", values.startDate)
      formData.append("con_ed", values.endDate)
      formData.append("con_website", values.website)
      formData.append("con_nassociation_id", values.nationalAssociation)

      formData.append("con_organizer_id", values.poc)

      formData.append("con_city", values.city)
      formData.append("con_state_id", values.state)
      formData.append("con_venue_id", values.venue)
      formData.append("con_type_id", values.conferenceType)
      formData.append("org_sec_name", values.organizingSecretaryName)
      formData.append("org_sec_mobile", values.number)
      formData.append("org_sec_email", values.email)
      formData.append("con_logo", image)
      console.log(formData)
      if (values.HostingChapter !== "") {
        formData.set("con_hassociation_id", values.HostingChapter)
      }
      axios
        .post(`${Baseurl}/insertConference`, formData)
        .then(response => {
          console.log("Form submitted successfully:", response.data)
          toggleToast("success", response.data.message)

          // Handle success, e.g., show a success message
          nestedformik.resetForm()
          navigate("/view-conference")
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toggleToast("error", error.response.data.message)
            } else {
              toggleToast("error", "Failed to add Conference .")
            }
          } else {
            toggleToast("error", "Failed to add Conference . Please try again.")
          }
          console.error("Error:", error)
        })
    },
  })
  // api Calling State
  const [states, setStates] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllStates`)
      .then(response => {
        setStates(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching states:", error)
      })
  }, [])
  // api Calling State
  // api Calling monthsData
  const [monthsData, setMonthsData] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllMonths`)
      .then(response => {
        setMonthsData(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching months:", error)
      })
  }, [])
  // api Calling monthsData
  // api Calling AllOrganizers

  const [AllOrganizers, setAllOrganizers] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllOrganizers`)
      .then(response => {
        setAllOrganizers(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching All Organizers:", error)
      })
  }, [])
  // api Calling AllOrganizers

  // api Calling Year
  const [yearsData, setyearsData] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllYears`)
      .then(response => {
        setyearsData(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching months:", error)
      })
  }, [])
  // api Calling Year
  // api Calling AssociationTypes
  const [AssociationTypes, setAssociationTypes] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllAssociations`)
      .then(response => {
        setAssociationTypes(response.data.data)
        console.log("getAllAssociations", response.data.data)
      })
      .catch(error => {
        console.error("Error fetching getAllAssociations:", error)
      })
  }, [])
  // api Calling AssociationTypes

  // Api calling OrganizerType
  const [OrganizerType, setOrganizerType] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllOrganizerTypes`)
      .then(response => {
        setOrganizerType(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching organizer types:", error)
      })
  }, [])
  // Api calling OrganizerType
  // api Calling getAllVenues
  const [AllVenues, setAllVenues] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllVenues`)
      .then(response => {
        setAllVenues(response.data.data)
        console.log("setAllVenues", response.data.data)
      })
      .catch(error => {
        console.error("Error fetching getAllVenues:", error)
      })
  }, [])
  // api Calling AllVenues
  // api Calling getAllConferenceTypes
  const [AllConferenceTypes, setAllConferenceTypes] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllConferenceTypes`)
      .then(response => {
        setAllConferenceTypes(response.data.data)
        console.log("getAllConferenceTypes", response.data.data)
      })
      .catch(error => {
        console.error("Error fetching setAllConferenceTypes:", error)
      })
  }, [])

  // api Calling getAllConferenceTypes
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Add Conference" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={nestedformik.handleSubmit}>
                    <Row>
                      {/* Conference Full Name */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="conferenceFullName">
                            Conference Full Name<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="conferenceFullName"
                            id="conferenceFullName"
                            placeholder="Enter Conference Full Name..."
                            className="form-control"
                            value={nestedformik.values.conferenceFullName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.conferenceFullName &&
                              nestedformik.errors.conferenceFullName
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.conferenceFullName}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Conference Short Name */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="conferenceShortName">
                            Conference Short Name<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="conferenceShortName"
                            id="conferenceShortName"
                            placeholder="Enter Conference Short Name..."
                            className="form-control"
                            value={nestedformik.values.conferenceShortName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.conferenceShortName &&
                              nestedformik.errors.conferenceShortName
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.conferenceShortName}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Month */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="month">
                            Month<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="month"
                            id="month"
                            className="form-control"
                            value={nestedformik.values.month}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.month &&
                              nestedformik.errors.month
                            }
                          >
                            <option value="">Select</option>
                            {monthsData.length > 0 ? (
                              monthsData.map((months, index) => (
                                <option key={index} value={months._id}>
                                  {months.month_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No months available</option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.month}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Year */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="year">
                            Year<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="year"
                            id="year"
                            className="form-control"
                            value={nestedformik.values.year}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.year &&
                              nestedformik.errors.year
                            }
                          >
                            <option value="">Select</option>
                            {yearsData.length > 0 ? (
                              yearsData.map((years, index) => (
                                <option key={index} value={years._id}>
                                  {years.year_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Years available</option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.year}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Start Date */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="startDate">
                            Start Date (DD/MM/YYYY){" "}
                            <em className="starred">*</em>
                          </Label>
                          <Input
                            type="date"
                            name="startDate"
                            id="startDate"
                            className="form-control"
                            value={nestedformik.values.startDate}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.startDate &&
                              nestedformik.errors.startDate
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.startDate}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* End Date */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="endDate">
                            End Date (DD/MM/YYYY)<em className="starred">*</em>
                          </Label>
                          <Input
                            type="date"
                            name="endDate"
                            id="endDate"
                            className="form-control"
                            value={nestedformik.values.endDate}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.endDate &&
                              nestedformik.errors.endDate
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.endDate}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Website */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="website">
                            Website<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="website"
                            id="website"
                            placeholder="Enter Website..."
                            className="form-control"
                            value={nestedformik.values.website}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.website &&
                              nestedformik.errors.website
                            }
                          />
                          <small>(example : https://www.abc.com)</small>
                          <FormFeedback>
                            {nestedformik.errors.website}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* National Association */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="nationalAssociation">
                            National Association<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="nationalAssociation"
                            id="nationalAssociation"
                            className="form-control"
                            value={nestedformik.values.nationalAssociation}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.nationalAssociation &&
                              nestedformik.errors.nationalAssociation
                            }
                          >
                            {/* AssociationTypes */}

                            <option value="">Select</option>
                            {AssociationTypes.length > 0 ? (
                              AssociationTypes.map((Association, index) => (
                                <option key={index} value={Association._id}>
                                  {Association.association_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No national Association Type available
                              </option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.nationalAssociation}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Hosting Chapter */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="HostingChapter">
                            Hosting Chapter
                          </Label>
                          <Input
                            type="select"
                            name="HostingChapter"
                            id="HostingChapter"
                            className="form-control"
                            value={nestedformik.values.HostingChapter}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.HostingChapter &&
                            //   nestedformik.errors.HostingChapter
                            // }
                          >
                            <option value="">Select</option>
                            {AssociationTypes.length > 0 ? (
                              AssociationTypes.map((Association, index) => (
                                <option key={index} value={Association._id}>
                                  {Association.association_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No national Association Type available
                              </option>
                            )}
                          </Input>
                          {/* {nestedformik.errors.HostingChapter &&
                          nestedformik.touched.HostingChapter ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.HostingChapter}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Col>

                      {/* PCO */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="email">
                            PCO <em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="poc"
                            id="poc"
                            placeholder="poc"
                            className={`form-control ${
                              nestedformik.errors.poc &&
                              nestedformik.touched.poc
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.poc}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.poc &&
                              nestedformik.errors.poc
                            }
                          >
                            <option value="">Select</option>
                            {AllOrganizers.length > 0 ? (
                              AllOrganizers.map((Organizers, index) => (
                                <option key={index} value={Organizers._id}>
                                  {Organizers.organizer_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Organizer available</option>
                            )}
                          </Input>
                          {nestedformik.errors.poc &&
                            nestedformik.touched.poc && (
                              <FormFeedback type="invalid">
                                {nestedformik.errors.poc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="Logo">Logo:</Label>
                          <div className="input-group">
                            <Input
                              type="file"
                              className="form-control"
                              id="inputGroupFile02"
                              onChange={onchangeImage}
                            />
                            <Label
                              className="input-group-text"
                              htmlFor="inputGroupFile02"
                            >
                              Upload
                            </Label>
                          </div>
                        </div>
                      </Col>
                      {/* Email */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="email">
                            Email<em className="starred">*</em>
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter Email"
                            className={`form-control ${
                              nestedformik.errors.email &&
                              nestedformik.touched.email
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.email}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.email &&
                              nestedformik.errors.email
                            }
                          />
                          {nestedformik.errors.email &&
                            nestedformik.touched.email && (
                              <FormFeedback type="invalid">
                                {nestedformik.errors.email}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* City */}
                      {/* State */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="state">
                            State<em className="starred">*</em>
                          </Label>

                          <Input
                            type="select"
                            name="state"
                            id="state"
                            className="form-control"
                            value={nestedformik.values.state}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.state &&
                              nestedformik.errors.state
                            }
                          >
                            <option value="">Select</option>
                            {states.length > 0 ? (
                              states.map((state, index) => (
                                <option key={index} value={state._id}>
                                  {state.state_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.state}
                          </FormFeedback>
                        </div>
                      </Col>{" "}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="city">
                            City<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            placeholder="Enter City..."
                            className="form-control"
                            value={nestedformik.values.city}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.city &&
                              nestedformik.errors.city
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.city}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Venue */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="venue">
                            Venue<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="venue"
                            id="venue"
                            placeholder="Enter Venue..."
                            className="form-control"
                            value={nestedformik.values.venue}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.venue &&
                              nestedformik.errors.venue
                            }
                          >
                            <option value="">Select</option>
                            {AllVenues.length > 0 ? (
                              AllVenues.map((Venues, index) => (
                                <option key={index} value={Venues._id}>
                                  {Venues.venue_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Venues available</option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.venue}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Conference Type */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="conferenceType">
                            Conference Type<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="conferenceType"
                            id="conferenceType"
                            className="form-control"
                            value={nestedformik.values.conferenceType}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.conferenceType &&
                              nestedformik.errors.conferenceType
                            }
                          >
                            {/* AllConferenceTypes */}

                            <option value="">Select</option>
                            {AllConferenceTypes.length > 0 ? (
                              AllConferenceTypes.map(
                                (ConferenceTypes, index) => (
                                  <option
                                    key={index}
                                    value={ConferenceTypes._id}
                                  >
                                    {ConferenceTypes.conference_type}
                                  </option>
                                )
                              )
                            ) : (
                              <option disabled>
                                No ConferenceTypes available
                              </option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.conferenceType}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* Organizing Secretary Name */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="organizingSecretaryName">
                            Organizing Secretary Name
                            <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="organizingSecretaryName"
                            id="organizingSecretaryName"
                            placeholder="Enter Organizing Secretary Name..."
                            className="form-control"
                            value={nestedformik.values.organizingSecretaryName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.organizingSecretaryName &&
                              nestedformik.errors.organizingSecretaryName
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.organizingSecretaryName}
                          </FormFeedback>
                        </div>
                      </Col>

                      {/* Number */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="number">
                            Number<em className="starred">*</em>
                          </Label>
                          <Input
                            type="tel"
                            name="number"
                            id="number"
                            placeholder="Enter Number"
                            className={`form-control ${
                              nestedformik.errors.number &&
                              nestedformik.touched.number
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.number}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.number &&
                              nestedformik.errors.number
                            }
                          />
                          {nestedformik.errors.number &&
                            nestedformik.touched.number && (
                              <FormFeedback type="invalid">
                                {nestedformik.errors.number}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddConference
