import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Baseurl } from "Baseurl"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
const AddAssociation = () => {
  document.title = "Add Association | ExpoCon- React Admin & Dashboard"
  const toggleToast = (type, message) => {
    toast[type](message)
  }
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState(null)
  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)

    // Read file content
    const reader = new FileReader()
    reader.onload = () => {
      const fileContent = reader.result
    }
    reader.readAsText(file)
  }
  const nestedformik = useFormik({
    initialValues: {
      AssociationName: "",
      Website: "",
      City: "",
      State: "",
      Address: "",
      Type: "",
    },
    validationSchema: Yup.object({
      AssociationName: Yup.string().required("Association Name is required"),
      Website: Yup.string().required("Website is required"),
      City: Yup.string().required("City is required"),
      State: Yup.string().required("State is required"),
      Address: Yup.string().required("Address is required"),
      Type: Yup.string().required("Type is required"),
    }),

    onSubmit: values => {
      console.log(nestedformik)
      console.log(selectedFile)
      console.log("Form Values:", values) // Log form values to console

      const postData = {
        association_name: values.AssociationName,
        association_website: values.Website,
        association_city: values.City,
        association_state_id: values.State, // Assuming State value contains the state ID
        association_address: values.Address,
        association_type_id: values.Type,
      }
      console.log("postData", postData)
      axios
        .post(`${Baseurl}/insertAssociation`, postData)
        .then(response => {
          toggleToast("success", "Association added successfully.")
          console.log(response)
          nestedformik.resetForm()
          navigate("/view-association")
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toggleToast("error", error.response.data.message)
            } else {
              toggleToast("error", "Failed to add venue.")
            }
          } else {
            toggleToast("error", "Failed to add venue. Please try again.")
          }
          console.error("Error:", error)
        })
    },
  })
  // api Calling State
  const [states, setStates] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllStates`)
      .then(response => {
        setStates(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching states:", error)
      })
  }, [])
  // api Calling State
  // api Calling getAllAssociationTypes
  const [getAllAssociationTypes, setgetAllAssociationTypes] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllAssociationTypes`)
      .then(response => {
        setgetAllAssociationTypes(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching states:", error)
      })
  }, [])
  // api Calling State

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Add Association" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={nestedformik.handleSubmit}>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="AssociationName">
                            Association Name <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="AssociationName"
                            id="AssociationName"
                            placeholder="Enter Association Name..."
                            className={`form-control ${
                              nestedformik.errors.AssociationName &&
                              nestedformik.touched.AssociationName
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.AssociationName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.AssociationName &&
                              nestedformik.errors.AssociationName
                            }
                          />
                          {nestedformik.errors.AssociationName &&
                          nestedformik.touched.AssociationName ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.AssociationName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className=" col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Website">
                            Website<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="Website"
                            id="Website"
                            placeholder="Enter Website..."
                            className={`form-control ${
                              nestedformik.errors.Website &&
                              nestedformik.touched.Website
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Website}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Website &&
                              nestedformik.errors.Website
                            }
                          />
                          <small>(example : https://www.abc.com)</small>
                          {nestedformik.errors.Website &&
                          nestedformik.touched.Website ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Website}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="City">
                            City<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="City"
                            id="City"
                            placeholder="Enter City..."
                            className={`form-control ${
                              nestedformik.errors.City &&
                              nestedformik.touched.City
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.City}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.City &&
                              nestedformik.errors.City
                            }
                          />
                          {nestedformik.errors.City &&
                          nestedformik.touched.City ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.City}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="State">
                            State<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="State"
                            id="State"
                            className="form-control"
                            value={nestedformik.values.State}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.State &&
                              nestedformik.errors.State
                            }
                          >
                            <option value="">Select</option>
                            {states.length > 0 ? (
                              states.map((state, index) => (
                                <option key={index} value={state._id}>
                                  {state.state_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>
                          {nestedformik.errors.State &&
                          nestedformik.touched.State ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.State}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Address">
                            Address<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="Address"
                            id="Address"
                            placeholder="Enter Address..."
                            className={`form-control ${
                              nestedformik.errors.Address &&
                              nestedformik.touched.Address
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Address}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Address &&
                              nestedformik.errors.Address
                            }
                          />
                          {nestedformik.errors.Address &&
                          nestedformik.touched.Address ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>

                      {/* getAllAssociationTypes */}
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Type">
                            Type<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="Type"
                            id="Type"
                            className="form-control"
                            value={nestedformik.values.Type}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Type &&
                              nestedformik.errors.Type
                            }
                          >
                            <option value="">Select</option>
                            {getAllAssociationTypes.length > 0 ? (
                              getAllAssociationTypes.map((Asso, index) => (
                                <option key={index} value={Asso._id}>
                                  {Asso.asso_type}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>
                          {nestedformik.errors.Type &&
                          nestedformik.touched.Type ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddAssociation
