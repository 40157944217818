import React, { useEffect, useState } from "react"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Baseurl } from "Baseurl"
import { Link } from "react-router-dom"

const ViewKeyContact = () => {
  const [contacts, setContacts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllContacts`)
      .then(response => {
        setContacts(response.data.data)
        console.log(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.error("Error fetching contacts:", error)
        setLoading(false)
      })
  }, [])

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "contact_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mobile",
        accessorKey: "contact_mobile",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "contact_email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Organizer",
        accessorKey: "contact_organizer_id.organizer_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Expo Name",
        accessorKey: "contact_expo_id.expo_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Conference",
        accessorKey: "contact_con_id.con_fullname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Partner",
        accessorKey: "contact_partner.partner_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Venue",
        accessorKey: "contact_venue_id.venue_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "contact_status",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="View Key Contact" />
        <div className="Addbuttonform">
          <Link to="/add-key-contact">
            <button className="btn btn-primary">Add Key Contact</button>
          </Link>
        </div>
        {loading ? (
          <p></p>
        ) : contacts.length === 0 ? (
          <p>No key contact records found.</p>
        ) : (
          <TableContainer
            columns={columns}
            data={contacts}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            loading={loading}
          />
        )}
      </div>
    </div>
  )
}

export default ViewKeyContact
