import React, { useEffect, useState } from "react"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Baseurl } from "Baseurl"
import { Link } from "react-router-dom"

const ViewVenue = () => {
  const [venues, setVenues] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllVenues`)
      .then(response => {
        setVenues(response.data.data)
        setLoading(false)
      })
      .catch(error => {
        console.error("Error fetching venues:", error)
        setLoading(false)
      })
  }, [])

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "venue_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Website",
        accessorKey: "venue_website",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "City",
        accessorKey: "venue_city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "State",
        accessorKey: "venue_state_id.state_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Address",
        accessorKey: "venue_address",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Map",
        accessorKey: "venue_map",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Phone",
        accessorKey: "venue_phone",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "venue_status",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="View Venue" />
        <div className="Addbuttonform">
          <Link to="/add-venue">
            <button className="btn btn-primary">Add Venue</button>
          </Link>
        </div>
        {loading ? (
          <p></p>
        ) : venues.length === 0 ? (
          <p>No venue records found.</p>
        ) : (
          <TableContainer
            columns={columns}
            data={venues}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            loading={loading}
          />
        )}
      </div>
    </div>
  )
}

export default ViewVenue
