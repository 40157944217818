import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
// getAllOrganizers
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Baseurl } from "Baseurl"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"

const AddExhibition = () => {
  document.title = "Add Exhibition | ExpoCon- React Admin & Dashboard"
  const toggleToast = (type, message) => {
    toast[type](message)
  }
  const navigate = useNavigate()

  const [image, setImage] = React.useState("")
  const [imageshow, setImageshow] = useState("")

  const onchangeImage = e => {
    const blogimage = e.target.files[0]
    console.log("image", blogimage)
    setImageshow(URL.createObjectURL(e.target.files[0]))
    setImage(blogimage)
  }
  const nestedformik = useFormik({
    initialValues: {
      ExhibitionName: "",
      ExhibitionType: "",
      Month: "",
      Year: "",
      StartDate: "",
      EndDate: "",
      Website: "",
      Organizer: "",
      City: "",
      State: "",
      Venue: "",
      Frequency: "",
      Logo: "",
    },
    validationSchema: Yup.object({
      ExhibitionName: Yup.string().required("Exhibition Name is required"),
      ExhibitionType: Yup.string().required("Exhibition Type is required"),
      Month: Yup.string().required("Month is required"),
      Year: Yup.string().required("Year is required"),
      StartDate: Yup.string().required("Start Date is required"),
      EndDate: Yup.string().required("End Date is required"),
      Website: Yup.string().required("Website is required"),
      Organizer: Yup.string().required("Organizer is required"),
      // City: Yup.string(),
      State: Yup.string().required("State is required"),
      Venue: Yup.string().required("Venue is required"),
    }),

    onSubmit: values => {
      console.log(values)
      const formData = new FormData()
      formData.append("expo_name", values.ExhibitionName)
      formData.append("exhibition_type_id", values.ExhibitionType)
      formData.append("expo_month_id", values.Month)
      formData.append("expo_year_id", values.Year)
      formData.append("expo_sd", values.StartDate)
      formData.append("expo_ed", values.EndDate)
      formData.append("expo_website", values.Website)
      formData.append("expo_organizer_id", values.Organizer)
      formData.append("expo_city", values.City)
      formData.append("expo_state_id", values.State)
      formData.append("expo_venue_id", values.Venue)
      formData.append("expo_frequency", values.Frequency)
      formData.append("expo_logo", image)
      console.log(formData)
      axios
        .post(`${Baseurl}/insertExhibition`, formData)
        .then(response => {
          console.log("Form submitted successfully:", response.data)
          toggleToast("success", response.data.message)

          // Handle success, e.g., show a success message
          nestedformik.resetForm()
          navigate("/view-exhibition")
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toggleToast("error", error.response.data.message)
            } else {
              toggleToast("error", "Failed to add venue.")
            }
          } else {
            toggleToast("error", "Failed to add venue. Please try again.")
          }
          console.error("Error:", error)
        })
    },
  })

  // api Calling State
  const [states, setStates] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllStates`)
      .then(response => {
        setStates(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching states:", error)
      })
  }, [])

  // api Calling State
  // api Calling monthsData
  const [monthsData, setMonthsData] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllMonths`)
      .then(response => {
        setMonthsData(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching months:", error)
      })
  }, [])
  // api Calling monthsData
  // api Calling monthsData
  const [yearsData, setyearsData] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllYears`)
      .then(response => {
        setyearsData(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching months:", error)
      })
  }, [])
  // api Calling monthsData
  // api Calling ExhibitionType
  const [ExhibitionType, setExhibitionTypea] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllExhibitionTypes`)
      .then(response => {
        setExhibitionTypea(response.data.data)
        console.log("setExhibitionTypea", response.data.data)
      })
      .catch(error => {
        console.error("Error fetching months:", error)
      })
  }, [])
  // api Calling ExhibitionType

  // api Calling AllOrganizers
  const [AllOrganizers, setAllOrganizers] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllOrganizers`)
      .then(response => {
        setAllOrganizers(response.data.data)
        console.log("setAllOrganizers", response.data.data)
      })
      .catch(error => {
        console.error("Error fetching AllOrganizers:", error)
      })
  }, [])
  // api Calling AllOrganizers

  // api Calling getAllVenues
  const [AllVenues, setAllVenues] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllVenues`)
      .then(response => {
        setAllVenues(response.data.data)
        console.log("setAllVenues", response.data.data)
      })
      .catch(error => {
        console.error("Error fetching getAllVenues:", error)
      })
  }, [])
  // api Calling AllVenues

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Add Exhibition" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={nestedformik.handleSubmit}>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="ExhibitionName">
                            Exhibition Name <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="ExhibitionName"
                            id="ExhibitionName"
                            placeholder="Enter Exhibition Name..."
                            className="form-control"
                            value={nestedformik.values.ExhibitionName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.ExhibitionName &&
                              nestedformik.errors.ExhibitionName
                            }
                          />
                          {nestedformik.errors.ExhibitionName &&
                          nestedformik.touched.ExhibitionName ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.ExhibitionName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="ExhibitionType">
                            Exhibition Type <em className="starred">*</em>
                          </Label>

                          <Input
                            type="select"
                            name="ExhibitionType"
                            id="ExhibitionType"
                            className="form-control"
                            value={nestedformik.values.ExhibitionType}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.ExhibitionType &&
                              nestedformik.errors.ExhibitionType
                            }
                          >
                            <option value="">Select</option>
                            {ExhibitionType.length > 0 ? (
                              ExhibitionType.map((Exhibition, index) => (
                                <option key={index} value={Exhibition._id}>
                                  {Exhibition.exhibition_type}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>
                          {nestedformik.errors.ExhibitionType &&
                          nestedformik.touched.ExhibitionType ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.ExhibitionType}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>

                    <Row>
                      {/* Month */}
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="month">
                            Month<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="Month"
                            id="Month"
                            className="form-control"
                            value={nestedformik.values.Month}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Month &&
                              nestedformik.errors.Month
                            }
                          >
                            <option value="">Select</option>
                            {monthsData.length > 0 ? (
                              monthsData.map((months, index) => (
                                <option key={index} value={months._id}>
                                  {months.month_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No months available</option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.Month}
                          </FormFeedback>
                        </div>
                      </Row>
                      {/* Year */}
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="year">
                            Year <em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="Year"
                            id="Year"
                            className="form-control"
                            value={nestedformik.values.Year}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Year &&
                              nestedformik.errors.Year
                            }
                          >
                            <option value="">Select</option>
                            {yearsData.length > 0 ? (
                              yearsData.map((years, index) => (
                                <option key={index} value={years._id}>
                                  {years.year_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Years available</option>
                            )}
                          </Input>
                          <FormFeedback>
                            {nestedformik.errors.Year}
                          </FormFeedback>
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      {/* Start Date */}
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="startDate">
                            Start Date(DD/MM/YYYY){" "}
                            <em className="starred">*</em>
                          </Label>
                          <Input
                            type="date"
                            name="StartDate"
                            id="StartDate"
                            className="form-control"
                            value={nestedformik.values.StartDate}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.StartDate &&
                              nestedformik.errors.StartDate
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.StartDate}
                          </FormFeedback>
                        </div>
                      </Row>
                      {/* End Date */}
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="endDate">
                            End Date (DD/MM/YYYY) <em className="starred">*</em>
                          </Label>
                          <Input
                            type="date"
                            name="EndDate"
                            id="EndDate"
                            className="form-control"
                            value={nestedformik.values.EndDate}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.EndDate &&
                              nestedformik.errors.EndDate
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.EndDate}
                          </FormFeedback>
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="City">City</Label>
                          <Input
                            type="text"
                            name="City"
                            id="City"
                            placeholder="Enter City..."
                            className={`form-control ${
                              nestedformik.errors.City &&
                              nestedformik.touched.City
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.City}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.City &&
                            //   nestedformik.errors.City
                            // }
                          />
                          {/* {nestedformik.errors.City &&
                          nestedformik.touched.City ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.City}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="State">
                            State<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="State"
                            id="State"
                            className="form-control"
                            value={nestedformik.values.State}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.State &&
                              nestedformik.errors.State
                            }
                          >
                            <option value="">Select</option>
                            {states.length > 0 ? (
                              states.map((state, index) => (
                                <option key={index} value={state._id}>
                                  {state.state_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>
                          {nestedformik.errors.State &&
                          nestedformik.touched.State ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.State}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Venue">
                            Venue<em className="starred">*</em>
                          </Label>

                          <Input
                            type="select"
                            name="Venue"
                            id="Venue"
                            className="form-control"
                            value={nestedformik.values.Venue}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Venue &&
                              nestedformik.errors.Venue
                            }
                          >
                            <option value="">Select</option>
                            {AllVenues.length > 0 ? (
                              AllVenues.map((Venues, index) => (
                                <option key={index} value={Venues._id}>
                                  {Venues.venue_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Venues available</option>
                            )}
                          </Input>

                          {nestedformik.errors.Venue &&
                          nestedformik.touched.Venue ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Venue}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Frequency">Frequency</Label>
                          <Input
                            type="text"
                            name="Frequency"
                            id="Frequency"
                            placeholder="Enter Frequency..."
                            className={`form-control ${
                              nestedformik.errors.Frequency &&
                              nestedformik.touched.Frequency
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Frequency}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.Frequency &&
                            //   nestedformik.errors.Frequency
                            // }
                          />
                          {/* {nestedformik.errors.Frequency &&
                          nestedformik.touched.Frequency ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Frequency}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Logo">Logo</Label>
                          <div className="input-group">
                            <Input
                              type="file"
                              className="form-control"
                              id="inputGroupFile02"
                              onChange={onchangeImage}
                            />
                            <Label
                              className="input-group-text"
                              htmlFor="inputGroupFile02"
                            >
                              Upload
                            </Label>
                          </div>
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Organizer">
                            Organizer<em className="starred">*</em>
                          </Label>
                          {/* <Input
                            type="text"
                            name="Organizer"
                            id="Organizer"
                            placeholder="Enter Organizer..."
                            className={`form-control ${
                              nestedformik.errors.Organizer &&
                              nestedformik.touched.Organizer
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Organizer}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Organizer &&
                              nestedformik.errors.Organizer
                            }
                          /> */}
                          <Input
                            type="select"
                            name="Organizer"
                            id="Organizer"
                            className="form-control"
                            value={nestedformik.values.Organizer}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Organizer &&
                              nestedformik.errors.Organizer
                            }
                          >
                            <option value="">Select</option>
                            {AllOrganizers.length > 0 ? (
                              AllOrganizers.map((Organizers, index) => (
                                <option key={index} value={Organizers._id}>
                                  {Organizers.organizer_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Organizer available</option>
                            )}
                          </Input>
                          {nestedformik.errors.Organizer &&
                          nestedformik.touched.Organizer ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Organizer}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      {/* Website */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="website">
                            Website* <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="Website"
                            id="Website"
                            placeholder="Enter Website..."
                            className="form-control"
                            value={nestedformik.values.Website}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Website &&
                              nestedformik.errors.Website
                            }
                          />
                          <small>(example : https://www.abc.com)</small>
                          <FormFeedback>
                            {nestedformik.errors.Website}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddExhibition
