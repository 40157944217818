import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Baseurl } from "Baseurl"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const AddOrganizer = () => {
  document.title = "Add Organizer | ExpoCon- React Admin & Dashboard"
  const toggleToast = (type, message) => {
    toast[type](message)
  }
  const navigate = useNavigate()
  const [image, setImage] = React.useState("")
  const [imageshow, setImageshow] = useState("")

  const onchangeImage = e => {
    const blogimage = e.target.files[0]
    console.log("image", blogimage)
    setImageshow(URL.createObjectURL(e.target.files[0]))
    setImage(blogimage)
  }
  // Retrieve userData from localStorage
  const userDataString = localStorage.getItem("userData")
  // Parse the string back into a JavaScript object
  const userData = JSON.parse(userDataString)
  // Access the nested user_id property
  const userId = userData.user.user_id

  // console.log(userId)

  const nestedformik = useFormik({
    initialValues: {
      OrganizerName: "",
      Website: "",
      City: "",
      State: "",
      Address: "",
      GoogleMapLink: "",
      Phone: "",
      OrganizerType: "",
      email: "",
      Mobile: "",
    },
    validationSchema: Yup.object({
      OrganizerName: Yup.string().required("This field is required"),
      Website: Yup.string().required("This field is required"),
      State: Yup.string().required("This field is required"),
      Address: Yup.string().required("This field is required"),
      OrganizerType: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      console.log(values)
      const formData = new FormData()
      formData.append("organizer_name", values.OrganizerName)
      formData.append("organizer_website", values.Website)
      formData.append("organizer_city", values.City)
      formData.append("organizer_state_id", values.State)
      formData.append("organizer_address", values.Address)
      formData.append("organizer_map", values.GoogleMapLink)
      formData.append("organizer_phone", values.Phone)
      formData.append("organizer_email", values.email)
      formData.append("organizer_mobile", values.Mobile)
      formData.append("organizer_type_id", values.OrganizerType)
      formData.append("user_id", userId)
      formData.append("organizer_logo", image)

      axios
        .post(`${Baseurl}/insertOrganizer`, formData)
        .then(response => {
          console.log("Response:", response.data)
          console.log("Response:", response.data.message)
          toggleToast("success", response.data.message)
          nestedformik.resetForm()
          navigate("/view-organizer")
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toggleToast("error", error.response.data.message)
            } else {
              toggleToast("error", "Failed to add organizer.")
            }
          } else {
            toggleToast("error", "Failed to add venue. Please try again.")
          }
          console.error("Error:", error)
        })
    },
  })
  // Api calling states

  const [states, setStates] = useState([])
  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllStates`)
      .then(response => {
        setStates(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching states:", error)
      })
  }, [])
  // Api calling states

  // Api calling OrganizerType
  const [OrganizerType, setOrganizerType] = useState([])

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllOrganizerTypes`)
      .then(response => {
        setOrganizerType(response.data.data)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching organizer types:", error)
      })
  }, [])
  // Api calling OrganizerType

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Add Organizer" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={nestedformik.handleSubmit}>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="OrganizerName">
                            Organizer Name <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="OrganizerName"
                            id="OrganizerName"
                            placeholder="Enter your Organizer Name..."
                            className="form-control"
                            value={nestedformik.values.OrganizerName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.OrganizerName &&
                              nestedformik.errors.OrganizerName
                                ? true
                                : false
                            }
                          />
                          <FormFeedback>
                            {nestedformik.errors.OrganizerName}
                          </FormFeedback>
                        </div>
                      </Row>
                      <Row className=" col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Website">
                            Website <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="Website"
                            id="Website"
                            placeholder="Enter your Organizer Name..."
                            className="form-control"
                            value={nestedformik.values.Website}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Website &&
                              nestedformik.errors.Website
                                ? true
                                : false
                            }
                          />
                          <small>(example : https://www.abc.com)</small>
                          <FormFeedback>
                            {nestedformik.errors.Website}
                          </FormFeedback>
                        </div>
                      </Row>
                    </Row>
                    {/* City */}
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="City">City</Label>
                          <Input
                            type="text"
                            name="City"
                            id="City"
                            placeholder="Enter your City Name..."
                            className="form-control"
                            value={nestedformik.values.City}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                          />
                        </div>
                      </Row>

                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="State">
                            State<em className="starred">*</em>
                          </Label>
                          <Input
                            type="select"
                            name="State"
                            id="State"
                            className="form-control"
                            value={nestedformik.values.State}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.State &&
                              nestedformik.errors.State
                            }
                          >
                            <option value="">Select</option>
                            {states.length > 0 ? (
                              states.map((state, index) => (
                                <option key={index} value={state._id}>
                                  {state.state_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No states available</option>
                            )}
                          </Input>
                          {nestedformik.errors.State &&
                            nestedformik.touched.State && (
                              <FormFeedback type="invalid">
                                {nestedformik.errors.State}
                              </FormFeedback>
                            )}
                        </div>
                      </Row>
                    </Row>
                    {/* Address */}
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Address">
                            Address <em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="Address"
                            id="Address"
                            placeholder="Enter your Address Name..."
                            className="form-control"
                            value={nestedformik.values.Address}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Address &&
                              nestedformik.errors.Address
                                ? true
                                : false
                            }
                          />
                          {nestedformik.errors.Address &&
                          nestedformik.touched.Address ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="GoogleMapLink">Google Map Link</Label>
                          <Input
                            type="text"
                            name="GoogleMapLink"
                            id="GoogleMapLink"
                            placeholder="Enter your Google Map Link "
                            className="form-control"
                            value={nestedformik.values.GoogleMapLink}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.GoogleMapLink &&
                              nestedformik.errors.GoogleMapLink
                                ? true
                                : false
                            }
                          />
                            <small>
                            (example:- https://www.google.com/maps?q=location)
                          </small>
                          {/* {nestedformik.errors.GoogleMapLink &&
                          nestedformik.touched.GoogleMapLink ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.GoogleMapLink}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                    </Row>
                    {/* Phone */}
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Phone">Phone (Landline) </Label>
                          <Input
                            type="tel"
                            name="Phone"
                            id="Phone"
                            placeholder="Enter your Phone"
                            className={`form-control ${
                              nestedformik.errors.Phone &&
                              nestedformik.touched.Phone
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Phone}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Phone &&
                              nestedformik.errors.Phone
                                ? true
                                : false
                            }
                          />
                          {/* {nestedformik.errors.Phone &&
                          nestedformik.touched.Phone ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Phone}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="formemail">Email</Label>
                          <Input
                            type="email"
                            id="formemail"
                            name="email"
                            placeholder="Enter your Email..."
                            className="form-control"
                            value={nestedformik.values.email}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                          />
                        </div>
                      </Row>
                    </Row>

                    <Row>
                      <div className="mb-3 col-md-6">
                        <Label htmlFor="Logo">Logo </Label>
                        <div className="input-group">
                          <Input
                            type="file"
                            className="form-control"
                            id="inputGroupFile02"
                            onChange={onchangeImage}
                          />
                          <Label
                            className="input-group-text"
                            htmlFor="inputGroupFile02"
                          >
                            Upload
                          </Label>
                        </div>
                      </div>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="OrganizerType">
                            Organizer Type <em className="starred">*</em>
                          </Label>

                          <Input
                            type="select"
                            name="OrganizerType"
                            id="OrganizerType"
                            className="form-control"
                            value={nestedformik.values.OrganizerType}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.OrganizerType &&
                              nestedformik.errors.OrganizerType
                            }
                          >
                            <option value="">Select</option>
                            {OrganizerType.length > 0 ? (
                              OrganizerType.map((organizer, index) => (
                                <option key={index} value={organizer._id}>
                                  {organizer.org_type}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No Organizer Type available
                              </option>
                            )}
                          </Input>
                          {nestedformik.errors.OrganizerType &&
                            nestedformik.touched.OrganizerType && (
                              <FormFeedback type="invalid">
                                {nestedformik.errors.OrganizerType}
                              </FormFeedback>
                            )}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Mobile">Mobile </Label>
                          <Input
                            type="tel"
                            name="Mobile"
                            id="Mobile"
                            placeholder="Enter your Mobile"
                            className={`form-control ${
                              nestedformik.errors.Mobile &&
                              nestedformik.touched.Mobile
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Mobile}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Mobile &&
                              nestedformik.errors.Mobile
                                ? true
                                : false
                            }
                          />
                          {nestedformik.errors.Mobile &&
                          nestedformik.touched.Mobile ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Mobile}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddOrganizer
