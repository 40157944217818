import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Baseurl } from "Baseurl"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"

const AddKeyContact = () => {
  document.title = "Add Key Contact | ExpoCon- React Admin & Dashboard"
  const toggleToast = (type, message) => {
    toast[type](message)
  }
  const navigate = useNavigate()

  const nestedformik = useFormik({
    initialValues: {
      FullName: "",
      Mobile: "",
      email: "",
      Organizer: null,
      Exhibition: "",
      Conferences: "",
      Partner: "",
      Venue: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Full Name is required"),
      Mobile: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
        .min(10, "Mobile number must be at least 10 characters")
        .max(12, "Mobile number cannot exceed 12 characters"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // Organizer: Yup.string().required("Organizer selection is required"),
      // Exhibition: Yup.string(),
      // Conferences: Yup.string(),
      // Partner: Yup.string(),
      // Venue: Yup.string(),
    }),

    onSubmit: values => {
      console.log("Form Values:", values) // Log form values to console

      // Prepare data for API request
      const requestData = {
        contact_name: values.FullName,
        contact_mobile: values.Mobile,
        contact_email: values.email,
        contact_status: "Active",
      }

      // Only add fields to the request data if they are filled in
      if (values.Organizer !== "") {
        requestData.contact_organizer_id = values.Organizer
      }
      if (values.Exhibition !== "") {
        requestData.contact_expo_id = values.Exhibition
      }
      if (values.Conferences !== "") {
        requestData.contact_con_id = values.Conferences
      }
      if (values.Partner !== "") {
        requestData.contact_partner = values.Partner
      }
      if (values.Venue !== "") {
        requestData.contact_venue_id = values.Venue
      }

      // Send data to API endpoint
      axios
        .post(`${Baseurl}/insertContact`, requestData)
        .then(response => {
          console.log("API Response:", response.data.message)
          toggleToast("success", response.data.message)

          nestedformik.resetForm()
          navigate("/view-key-contact")
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toggleToast("error", error.response.data.message)
            } else {
              toggleToast("error", "Failed to add venue.")
            }
          } else {
            toggleToast("error", "Failed to add venue. Please try again.")
          }
          console.error("Error:", error)
        })
    },
  })

  // State for API data
  const [AllVenues, setAllVenues] = useState([])
  const [AllOrganizers, setAllOrganizers] = useState([])
  const [exhibitions, setExhibitions] = useState([])
  const [Conferences, setConferences] = useState([])
  const [AllPartners, setAllPartners] = useState([])

  // Fetch API data on component mount
  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllVenues`)
      .then(response => {
        setAllVenues(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching All Venues:", error)
      })

    axios
      .get(`${Baseurl}/getAllOrganizers`)
      .then(response => {
        setAllOrganizers(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching All Organizers:", error)
      })

    axios
      .get(`${Baseurl}/getAllExhibitions`)
      .then(response => {
        setExhibitions(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching Exhibitions:", error)
      })

    axios
      .get(`${Baseurl}/getAllConferences`)
      .then(response => {
        setConferences(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching Conferences:", error)
      })

    axios
      .get(`${Baseurl}/getAllPartners`)
      .then(response => {
        setAllPartners(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching All Partners:", error)
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Add Key Contact" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={nestedformik.handleSubmit}>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="FullName">
                            Full Name<em className="starred">*</em>
                          </Label>
                          <Input
                            type="text"
                            name="FullName"
                            id="FullName"
                            placeholder="Enter your Full Name..."
                            className="form-control"
                            value={nestedformik.values.FullName}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.FullName &&
                              nestedformik.errors.FullName
                            }
                          />
                          {nestedformik.errors.FullName &&
                          nestedformik.touched.FullName ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.FullName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Mobile">
                            Mobile<em className="starred">*</em>
                          </Label>
                          <Input
                            type="tel"
                            name="Mobile"
                            id="Mobile"
                            placeholder="Enter your Mobile Number..."
                            className={`form-control ${
                              nestedformik.errors.Mobile &&
                              nestedformik.touched.Mobile
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.Mobile}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.Mobile &&
                              nestedformik.errors.Mobile
                            }
                          />
                          {nestedformik.errors.Mobile &&
                          nestedformik.touched.Mobile ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Mobile}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="email">
                            Email<em className="starred">*</em>
                          </Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your Email Address..."
                            className={`form-control ${
                              nestedformik.errors.email &&
                              nestedformik.touched.email
                                ? "is-invalid"
                                : ""
                            }`}
                            value={nestedformik.values.email}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            invalid={
                              nestedformik.touched.email &&
                              nestedformik.errors.email
                            }
                          />
                          {nestedformik.errors.email &&
                          nestedformik.touched.email ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Organizer">Organizer</Label>
                          <Input
                            type="select"
                            name="Organizer"
                            id="Organizer"
                            className="form-control"
                            value={nestedformik.values.Organizer}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.Organizer &&
                            //   nestedformik.errors.Organizer
                            // }
                          >
                            <option value="">Select</option>
                            {AllOrganizers.length > 0 ? (
                              AllOrganizers.map((Organizers, index) => (
                                <option key={index} value={Organizers._id}>
                                  {Organizers.organizer_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Organizer available</option>
                            )}
                          </Input>
                          {/* {nestedformik.errors.Organizer &&
                          nestedformik.touched.Organizer ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Organizer}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Exhibition">Exhibition</Label>
                          <Input
                            type="select"
                            name="Exhibition"
                            id="Exhibition"
                            className="form-control"
                            value={nestedformik.values.Exhibition}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.Exhibition &&
                            //   nestedformik.errors.Exhibition
                            // }
                          >
                            <option value="">Select</option>
                            {exhibitions.length > 0 ? (
                              exhibitions.map((exhibition, index) => (
                                <option key={index} value={exhibition._id}>
                                  {exhibition.expo_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Exhibitions available</option>
                            )}
                          </Input>
                          {/* {nestedformik.errors.Exhibition &&
                          nestedformik.touched.Exhibition ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Exhibition}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Conferences">Conferences</Label>
                          <Input
                            type="select"
                            name="Conferences"
                            id="Conferences"
                            className="form-control"
                            value={nestedformik.values.Conferences}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.Conferences &&
                            //   nestedformik.errors.Conferences
                            // }
                          >
                            <option value="">Select</option>
                            {Conferences.length > 0 ? (
                              Conferences.map((Conference, index) => (
                                <option key={index} value={Conference._id}>
                                  {Conference.con_fullname}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Conferences available</option>
                            )}
                          </Input>
                          {/* {nestedformik.errors.Conferences &&
                          nestedformik.touched.Conferences ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Conferences}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Partner">Partner</Label>
                          <Input
                            type="select"
                            name="Partner"
                            id="Partner"
                            className="form-control"
                            value={nestedformik.values.Partner}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.Partner &&
                            //   nestedformik.errors.Partner
                            // }
                          >
                            <option value="">Select</option>
                            {AllPartners.length > 0 ? (
                              AllPartners.map((Partner, index) => (
                                <option key={index} value={Partner._id}>
                                  {Partner.partner_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Conferences available</option>
                            )}
                          </Input>
                          {/* {nestedformik.errors.Partner &&
                          nestedformik.touched.Partner ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Partner}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                      <Row className="mb-3 col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="Venue">Venue</Label>
                          <Input
                            type="select"
                            name="Venue"
                            id="Venue"
                            className="form-control"
                            value={nestedformik.values.Venue}
                            onChange={nestedformik.handleChange}
                            onBlur={nestedformik.handleBlur}
                            // invalid={
                            //   nestedformik.touched.Venue &&
                            //   nestedformik.errors.Venue
                            // }
                          >
                            <option value="">Select</option>
                            {AllVenues.length > 0 ? (
                              AllVenues.map((Venues, index) => (
                                <option key={index} value={Venues._id}>
                                  {Venues.venue_name}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Venues available</option>
                            )}
                          </Input>
                          {/* {nestedformik.errors.Venue &&
                          nestedformik.touched.Venue ? (
                            <FormFeedback type="invalid">
                              {nestedformik.errors.Venue}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Row>
                    </Row>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddKeyContact
