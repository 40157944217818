import React, { useEffect, useState } from "react"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Baseurl } from "Baseurl"
import { Link } from "react-router-dom"

const ViewConference = () => {
  const [conferences, setConferences] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`${Baseurl}/getAllConferences`)
      .then(response => {
        setConferences(response.data.data)
        setLoading(false)
        console.log(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching conferences:", error)
        setLoading(false)
      })
  }, [])

  const columns = React.useMemo(
    () => [
      {
        header: "Full Name",
        accessorKey: "con_fullname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Short Name",
        accessorKey: "con_shortname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Month",
        accessorKey: "con_month_id.month_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Year",
        accessorKey: "con_year_id.year_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Start Date",
        accessorKey: "con_sd",
        enableColumnFilter: false,
        enableSorting: true,
        // cell: ({ cellValue }) => {
        //   console.log("cellValue:", cellValue) // Debugging statement
        //   return cellValue ? cellValue.substring(0, 5) : "Start Date"
        // },
      },
      {
        header: "End Date",
        accessorKey: "con_ed",
        enableColumnFilter: false,
        enableSorting: true,
        //   cell: ({ cellValue }) =>
        //     cellValue ? cellValue.substring(0, 5) : "End Date",
      },
      {
        header: "Website",
        accessorKey: "con_website",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "National Association",
        accessorKey: "con_nassociation_id.association_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Hosting Chapter",
        accessorKey: "con_hassociation_id.association_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Organizer",
        accessorKey: "con_organizer_id.organizer_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "City",
        accessorKey: "con_city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "State",
        accessorKey: "con_state_id.state_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Venue",
        accessorKey: "con_venue_id.venue_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Conference Type",
        accessorKey: "con_type_id.conference_type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Organizing Secretary Name",
        accessorKey: "org_sec_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Phone",
        accessorKey: "org_sec_mobile",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "org_sec_email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "con_status",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="View Conference" />
        <div className="Addbuttonform">
          <Link to="/add-conference">
            <button className="btn btn-primary">Add Conference</button>
          </Link>
        </div>
        {loading ? (
          <p></p>
        ) : (
          <TableContainer
            columns={columns}
            data={conferences}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            loading={false} // Pass loading as false when data is loaded
          />
        )}
      </div>
    </div>
  )
}

export default ViewConference
